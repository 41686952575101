initLicenseAutocomplete = () => {
  $('.license-autocomplete').devbridgeAutocomplete({
    dataType: 'json',
    serviceUrl: '/search/license_autocomplete',
    triggerSelectOnValidInput: false,
  })
}


initAwesomeSearch = () => {
  options = {
    dataType: 'json',
    serviceUrl: '/search/autocomplete',
    triggerSelectOnValidInput: false,

    onSelect: (record) => { window.location = record.data },

    formatResult: (suggestion, currentValue) => {
      icon = ''
      details = ''

      if (suggestion.kind == 'Category') {
        icon = "<i class='fa fa-bars'></i> "
        if (suggestion.projects) {
          details = " - " + suggestion.projects + " projects"
        }
      } else if (suggestion.kind == 'Project') {
        icon = "<i class='fa fa-code'></i> "
      } else if (suggestion.kind == 'Tag') {
        icon = "<i class='fa fa-tag'></i> "
      } else if (suggestion.kind == 'Search') {
        icon = "<i class='fa fa-search'></i> "
      }

      return icon + suggestion.value + details
    }
  }
  $('.search-autocomplete').devbridgeAutocomplete(options)
}

initContributeProjectTags = () => {
  options = {
    dataType: 'json',
    serviceUrl: '/search/tags_autocomplete',
    triggerSelectOnValidInput: false,
    onSelect: (record) => { window.location = record.data },
  }

  $('.tags-autocomplete').devbridgeAutocomplete(options)
}

initSuggestedChangesFunctionality = () => {
  hide_spinner = (change_item, kind) => {
    change_item.find(`.votes-${kind}-spin`).hide()
    change_item.find(`.votes-${kind}-num`).show()
  }

  show_spinner = (change_item, kind) => {
    change_item.find(`.votes-${kind}-num`).hide()
    change_item.find(`.votes-${kind}-spin`).show()
  }

  thumbs_approve = (change_item) => {
    change_item.find('.fa-thumbs-o-up').toggleClass('fa-thumbs-o-up fa-thumbs-up')
    change_item.find('.fa-thumbs-down').toggleClass('fa-thumbs-down fa-thumbs-o-down')
  }

  thumbs_reject = (change_item) => {
    change_item.find('.fa-thumbs-o-down').toggleClass('fa-thumbs-o-down fa-thumbs-down')
    change_item.find('.fa-thumbs-up').toggleClass('fa-thumbs-up fa-thumbs-o-up')
  }

  do_vote = (change_item, kind) => {
    change_id = change_item.data('id')
    vote_context = change_item.data('vote-context')
    vote_path = `/${vote_context}/${change_id}/vote`
    params = { kind: kind }
    params[$("meta[name=csrf-param]").attr('content')] = $("meta[name=csrf-token]").attr('content')

    $.post(vote_path, params, (data) => {
      $(change_item).find(".votes-approve-num").text(data.votes_approve)
      $(change_item).find(".votes-reject-num").text(data.votes_reject)
      hide_spinner(change_item, kind)
    })
  }

  document.querySelectorAll('.suggested-change .do-vote').forEach(element => {
    element.addEventListener('click', (event) => {
      const target = event.currentTarget
      const kind = target.dataset.kind

      if (kind == 'approve' && target.querySelectorAll('.fa-thumbs-up').length == 1) return null
      if (kind == 'reject' && target.querySelectorAll('.fa-thumbs-down').length == 1) return null

      change_item = $(target).closest('.suggested-change')
      if (kind == 'approve') {
        thumbs_approve(change_item)
      } else if (kind == 'reject') {
        thumbs_reject(change_item)
      } else {
        console.log('unknown kind')
      }
      show_spinner(change_item, kind)
      do_vote(change_item, kind)
    })
  })
}

gaTrack = () => {
  if (window.ga != undefined) {
    ga('set', 'location', location.href.split('#')[0])
    ga('send', 'pageview')
  }
}

initResponsiveNavbar = () => {
  const navbarToggle = document.querySelectorAll('.toggle-mobile-menu')[0]
  if (navbarToggle) {
    navbarToggle.addEventListener('click', function () {
      // Get the target from the "data-target" attribute
      const targetId = navbarToggle.dataset.target;
      const target = document.getElementById(targetId);

      navbarToggle.classList.toggle('is-active');
      target.classList.toggle('is-active');
    })
  }
}

initClosableNotice = () => {
  const btn = document.querySelectorAll('.close-button')[0]
  if (btn) {
    btn.addEventListener('click', function () {
      const notice = btn.closest('.callout')
      if (notice) { notice.remove() }
    })
  }
}

onDocumentReady = () => {
  // https://get.foundation/sites/docs/javascript.html
  // $(document).foundation()
  initAwesomeSearch()
  initContributeProjectTags()
  initSuggestedChangesFunctionality()
  initLicenseAutocomplete()
  // initSuggestChangesTags()
  initResponsiveNavbar()
  initClosableNotice()
  gaTrack()
}

document.addEventListener('turbo:load', onDocumentReady)
