export function token() {
  let element: HTMLMetaElement | null = document.head.querySelector("[name=csrf-token]")
  if (element === null) return null
  return element.content
}

export default class RailsCsrf {
  static authenticityHeaders(existing = {}) {
    const csrfToken = token()
    if (csrfToken) {
      return {
        ...existing,
        'X-CSRF-Token': csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
      }
    } else {
      return existing
    }
  }

  static postBody(data: object, method = null) {
    return {
      method: method || 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: RailsCsrf.authenticityHeaders(),
      body: data,
    }
  }

  static postJSON(data: object) {
    return {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: RailsCsrf.authenticityHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }),
      body: data,
    }
  }
}
