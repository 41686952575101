// import initPrefetchOnHoverintent from './prefetch_on_hoverintent'
import initTrackEvents from './track_events'
import initHasClickableUrls from './has_clickable_urls'

// Things to be executed when the page loads
const onDocumentReady = () => {
  // @ts-ignore
  // gtag('config', 'UA-10949806-18', { 'page_path': window.location.pathname, 'anonymize_ip': true })
  // @ts-ignore
  gtag('config', 'G-735XEXJ594', { 'page_path': window.location.pathname, 'anonymize_ip': true })

  // initPrefetchOnHoverintent()
  initTrackEvents()
  initHasClickableUrls()
}

document.addEventListener('turbo:load', onDocumentReady)
