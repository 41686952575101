export const apiPath = (path: string) => (`/api${path}`)

export const formData = (params: object) => {
  let data = new FormData()
  Object.entries(params).map(([key, value]) => {
    data.append(key, value as string)
  })

  return data
}
