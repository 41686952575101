import RailsCsrf from './rails_csrf'
import { formData, apiPath } from './internal_api'

const clickSelector = '.trck-evnt'
const autoSelector = '.track-auto'

function post(url: string, data: object) {
  fetch(url, {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: RailsCsrf.authenticityHeaders(),
    body: formData(data),
  })
}

function getADataset(event: Event): DOMStringMap | null {
  let element = event.target as HTMLElement | null

  if (element === null) return null

  if (element.tagName === 'A') {
    return element.dataset
  } else {
    if (!element.parentNode) return null
    return (element.parentNode as HTMLElement).dataset
  }
}

function trackEvent(event: Event) {
  const data = getADataset(event)
  if (data === null) return
  post(apiPath('/ahoy/event'), data)
}

function trackAuto(element: HTMLElement) {
  const data = element.dataset
  if (data === null) return
  post(apiPath('/ahoy/event'), data)
}

export default function initTrackEvents(): void {
  document.querySelectorAll(clickSelector).forEach((element) => {
    element.addEventListener('click', trackEvent)
  })
  document.querySelectorAll(autoSelector).forEach((element) => {
    trackAuto(element as HTMLElement)
  })
}
