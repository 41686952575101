const maxLabelSize = 35
const urlRegex = /(\(|\s|\>|^)(https?:\/\/([^\s]+?))(\<|\s|\)|$)/g

const urlReplacer = (match: string, left: string, url:string, label:string, right:string) => {
  const partLengths = label.split('-').map((part) => part.length)
  const maxPartLength = Math.max(...partLengths)
  const urlLabel = maxPartLength > maxLabelSize ? label.slice(0, maxLabelSize) + "..." : label
  return `${left}<a href='${url.replace(/(\.|\!|\?)$/, '')}' rel='nofollow ugc' target='_blank'>${urlLabel}</a>${right}`
}

export default function hasClickableUrls() {
  document.querySelectorAll('.has-clickable-urls').forEach((el) => {
    el.innerHTML = el.innerHTML.replace(urlRegex, urlReplacer)
  })
}
